@import "../../styles/partials/mixins";
@import "../../styles/partials/variables";

.mainPage {

  &__main {
    height: calc(100vh - 94px);
    @include display-flex(column-reverse,center,center);
    @include paddingSection;
    margin-bottom: 5rem;
    @include desktop {
    margin-bottom: 0rem;
    @include display-flex(column,center,center);
    }
  }

  &__top {
    width: 100%;
    @include display-flex(column,center,center);
    @include desktop {
      @include display-flex(row,center,center);
    }
  }

  &__left {
    width: 80%;
    margin-bottom: 2rem;
    @include desktop {
      margin-bottom: 0rem;
      width: 50%;
      height: 100%;
      @include display-flex(column,center,flex-start);
    }
  }

  &__title {
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    @include desktop {
      font-weight: 600;
      font-size: 42px;
      line-height: 60px;
    }
    a {
      text-decoration: none;
    }
  }

  &__content {
    margin: 2rem 0rem 1.5rem 0rem;
    font-size: 21px;
    line-height: 34px;
    font-weight: 400;
    a {
      text-decoration: none;
    }
  }

  &__arrow {
    margin-top: 3rem;
    display: none;
    cursor: pointer;
    transition: 0.3s all ease;
    @include desktop {
      display: flex;
      height: 48px;
    }
    &:hover {
      transform: translateY(0.5rem);
    }
  }

  &__right {
    height: 100%;
    @include display-flex(row,flex-end,flex-start);
    margin-top: 0px;
    width: 50%;
    .cloud {
      z-index: 9000;
      position: absolute;
    }
    .cloud1 {
      display: none;
      @include desktop {
        display: flex;
        left: 52%;
        top: 36%;
        @include animation-cloud(float1, 0, 30%);
        animation: float1 6s linear infinite;
      }
    }
    .cloud2 {
      display: none;
      @include desktop {
        display: flex;
        left: 57%;
        top: 28%;
        @include animation-cloud(float1, 0, 90%);
        animation: float1 6s linear infinite;
      }
    }
    .cloud3 {
      display: none;
      @include desktop {
        display: flex;
        left: 72%;
        top: 23%;
        @include animation-cloud(float1, 0, 40%);
        animation: float1 6s linear infinite;
      }
      @include wide {
        left: 67%;
        top: 29%;
      }
    }
    .cloud4 {
      display: none;
      @include desktop {
        width: 70px;
        display: flex;
        left: 80%;
        top: 24%;
        @include animation-cloud(float1, 0, 40%);
        animation: float1 8s linear infinite;
      }
      @include wide {
        left: 74%;
        top: 25%;
      }
    }
    .cloud5 {
      display: none;
      @include desktop {
        display: flex;
        left: 88%;
        top: 33%;
        @include animation-cloud(float1, 0, 90%);
        animation: float1 6s linear infinite;
      }
      @include wide {
        left: 82%;
        top: 32%;
      }
    }
  }

  &__illustration {
    max-width: 612px;
    overflow: hidden;
    z-index: 1;
  }

  &__bottom {
    @include display-flex(row,center,center);
  }
}

.myWork {

  .subtitle {
    font-size: 44px;
    font-weight: 600;
    line-height: 55px;
    @include display-flex(row,center,center);
    &__span {
      color: $accent-day;
    }
  }
  .worklist-box {
    width: 100%;
  }
}


// DM
.mainPage-dm {

  &__main {
    height: calc(100vh - 94px);
    @include display-flex(column-reverse,center,center);
    @include paddingSection;
    margin-bottom: 5rem;
    @include desktop {
      margin-bottom: 0rem;
      @include display-flex(column,center,center);
    }
  }

  &__top {
    width: 100%;
    @include display-flex(column,center,center);
    @include desktop {
      @include display-flex(row,center,center);
    }
  }

  &__left {
    width: 80%;
    margin-bottom: 2rem;
    @include desktop {
      margin-bottom: 0rem;
      width: 50%;
      height: 100%;
      @include display-flex(column,center,flex-start);
     }
  }

  &__title {
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    @include desktop {
      font-weight: 600;
      font-size: 42px;
      line-height: 60px;
    }
    a {
      text-decoration: none;
    }
  }

  &__content {
    margin: 2rem 0rem 1.5rem 0rem;
    font-size: 21px;
    line-height: 34px;
    font-weight: 400;
    a {
      text-decoration: none;
    }
  }

  &__arrow {
    margin-top: 3rem;
    display: none;
    cursor: pointer;
    transition: 0.3s all ease;
    @include desktop {
      display: flex;
      height: 48px;
    }
    &:hover {
      transform: translateY(0.5rem);
    }
  }

  &__right {
    height: 100%;
    @include display-flex(row,flex-end,flex-start);
    margin-top: 0px;
    width: 50%;
    .fly {
      z-index: 9000;
      position: absolute;
    }
    .fly1 {
      display: none;
      @include desktop {
        display: flex;
        left: 53%;
        top: 40%;
        @include animation-fly(bug2, 0, 0);
        animation: bug2 6s linear infinite;
      }
      @include wide {
        left: 53%;
        top: 40%;
      }
    }
    .fly2 {
      display: none;
      max-width: 50px;
      @include desktop {
        display: flex;
        left: 57%;
        top: 28%;
        @include animation-fly(bug2, 0, 0);
        animation: bug2 7s linear infinite;
      }
      @include wide {
        left: 55%;
        top: 26%;
      }
    }
    .fly3 {
      display: none;
      @include desktop {
        display: flex;
        left: 73%;
        top: 26%;
        @include animation-fly(bug1, 0, 0);
        animation: bug1 8s linear infinite;
      }
      @include wide {
        left: 68%;
        top: 26%;
      }
    }
    .fly4 {
      display: none;
      max-width: 50px;
      @include desktop {
        display: flex;
        left: 85%;
        top: 29%;
        @include animation-fly(bug2, 0, 0);
        animation: bug2 7s linear infinite;
      }
      @include wide {
        left: 79%;
        top: 32%;
      }
    }
  }

  &__illustration {
    max-width: 612px;
    overflow: hidden;
    z-index: 1;
  }

  &__bottom {
    @include display-flex(row,center,center);
  }

}

.myWork--dm {

  .subtitle {
    font-size: 44px;
    font-weight: 600;
    line-height: 55px;
    @include display-flex(row,center,center);
    &__span--dm {
      color: $accent-night;
    }
  }
  .worklist-box {
    width: 100%;
  }
}


