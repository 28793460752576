@import "../../styles/partials/variables";
@import "../../styles/partials/mixins";

.contact {
  @include display-flex(column,center,center);
  margin: 3rem 0rem;

  &__title {
    font-size: 45px;
    line-height: 70px;
    font-weight: 500;
    margin-bottom: 1rem;
  }
  &__content {
    font-size: 25px;
    line-height: 39px;
    font-weight: 400;
  }

  &__email {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  &__img {
    margin: 6rem 0rem 0rem 0rem;
  }
}