@import "../../styles/partials/variables";
@import "../../styles/partials/mixins";

.worklist {
  margin: 0 auto;
  @include paddingSection;
  @include display-flex(column,center,center);

  .project {
    width: 100%;
    padding: 0;
    margin-bottom: 10rem;
    background-color: transparent;
    @include display-flex(column-reverse,space-between,center);
    @include desktop {
      @include display-flex(row,space-between,center);
    }

    &--reverse {
      @include display-flex(column-reverse,space-between,center);
      @include desktop {
        @include display-flex(row-reverse,space-between,center);
      }
    }
    .hideImg {
      opacity: 0;
    }
    .showImg {
      animation: fadeIn 3s;
      width: 100%;
      margin-top: 5rem;
      @include tablet {
        width: auto;
      }

      @keyframes fadeIn {
        0% { opacity: 0; }
        100% { opacity: 1;}
      }
    }

  }

  .projectDm {
    width: 100%;
    padding: 0;
    margin-bottom: 10rem;
    background-color: transparent;
    @include display-flex(column-reverse,space-between,center);
    @include desktop {
      @include display-flex(row,space-between,center);
    }

    &--reverse {
      @include display-flex(column-reverse,space-between,center);
      @include desktop {
        @include display-flex(row-reverse,space-between,center);
      }
    }
    .hideImg {
      opacity: 0;
    }
    .showImg {
      animation: fadeIn 3s;
      width: 100%;
      margin-top: 5rem;
      @include tablet {
        width: auto;
      }

      @keyframes fadeIn {
        0% { opacity: 0; }
        100% { opacity: 1; }
      }
    }
  }
}