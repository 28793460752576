@import "../../styles/partials/mixins";
@import "../../styles/partials/variables";

.project {
  background-color: #FFF;
  color: $font-lm;
  padding: 2rem;
  @include desktop {
    padding: 0rem;
  }
  .top {
    @include display-flex(row,space-between,center);
    max-width: 1100px;
    padding: 2rem 0rem;
    margin: 0 auto;
    margin-bottom: 1.5rem;
    &__link {
      color: $font-lm;
      text-decoration: none;
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 1.8px;
      font-weight: 600;
      @include display-flex(row,center,center);

      .arrowIcon-left {
        transition: 0.3s all ease;
        margin-right: 0.5rem;
      }
      .arrowIcon-right {
        transition: 0.3s all ease;
        margin-left: 0.5rem;
      }
      &:hover .arrowIcon-left {
        transition: 0.3s all ease;
        transform: translateX(-0.5rem);
      }
      &:hover .arrowIcon-right {
        transition: 0.3s all ease;
        transform: translateX(0.5rem);
      }
    }
  }
  .subsection {
    padding: 1.5rem 0rem;
    @include desktop {
      max-width: 1100px;
      margin: 0 auto;
    }
    .content {
    @include display-flex(row,center,flex-start);

      // LEFT SECTION
      &__left {
        width: 40%;
        @include display-flex(row,flex-start,center);
      }
      &__leftIntro {
        width: 40%;
        height: 100%;
        @include display-flex(row,flex-start,flex-start);
        margin-top: 10px;
      }

      &__yearBox {
        margin-right: 3rem;
      }

      &__introTitle {
        font-size: 14px;
        line-height: 17px;
        font-weight: 600;
        margin-bottom: 0.5rem;
        text-transform: uppercase;
      }
      &__year {
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
      }

      &__respItem {
        font-size: 16px;
        line-height: 32px;
        font-weight: 400;
      }

      &__title {
        font-size: 28px;
        line-height: 35px;
        font-weight: 600;
        max-width: 240px;
      }

      // RIGHT SECTION
      &__right {
        width: 60%;
      }
      &__mainTitle {
        font-size: 38px;
        line-height: 47px;
        font-weight: 600;
        margin-bottom: 3rem;
      }
      &__text {
        font-size: 17px;
        line-height: 27px;
        margin-bottom: 2rem;

        a {
          color: $font-lm;
        }

        &--bold {
          font-weight: bold;
        }
        &--fa {
          margin-bottom: 0rem;
        }
      }
    }
  }
  .bottom {
    @include display-flex(row,space-between,center);
    max-width: 1100px;
    padding: 2rem 0rem;
    margin: 0 auto;
    margin-top: 3rem;
    &__link {
      color: $font-lm;
      text-decoration: none;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 600;
      @include display-flex(row,center,center);

      .arrowIcon-left {
        transition: 0.3s all ease;
        margin-right: 0.5rem;
      }
      .arrowIcon-right {
        transition: 0.3s all ease;
        margin-left: 0.5rem;
      }
      &:hover .arrowIcon-left {
        transition: 0.3s all ease;
        transform: translateX(-0.5rem);
      }
      &:hover .arrowIcon-right {
        transition: 0.3s all ease;
        transform: translateX(0.5rem);
      }
    }  }

    .imgContainer {
      @include display-flex(column,center,center);
      margin: 2.5rem 0rem 2.5rem 0rem;

      &__img {
        width: 100%;
        @include desktop {
          max-width: 1000px;
        }

        &--full {
          width: 105%;
          animation: fadeIn 3s;
          margin-bottom: 2.5rem;
          @include desktop {
            width: 100%;
          }

          @keyframes fadeIn {
            0% { opacity: 0; }
            100% { opacity: 1;}
          }
        }
      }
      &--last {
        margin-bottom: 4.5rem;
      }
      &--spec {
        margin-bottom: 1.5rem;
      }
      &__legend {
        margin: 1rem 0rem 1rem 0rem;
        font-size: 12px;
        line-height: 15px;
        &--wide {
          margin-bottom: 3rem;
          &-end {
            margin-bottom: 0rem;
          }
        }
      }
      &__arrow {
        margin: 1.5rem 0rem 0rem 0rem;
      }

      &__vidBox {
        position: relative;
        padding-bottom: 35%;
        width: 70%;
        max-width: 1000px;
        margin: 0 auto;
        margin-top: 2.5rem;
        margin-bottom: 4.5rem;
      }
      &__video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
}

