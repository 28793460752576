@import "./variables";

// media queries
@mixin tablet {
  @media (min-width: 768px) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin wide {
  @media (min-width: 1669px) {
    @content;
  }
}

// general mixins:

@mixin display-flex($direction, $justify, $align) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}

@mixin paddingSection {
  max-width: 1300px;
  margin: 0 auto;
  @include tablet {
    padding: 0rem 2rem;
  }
  @include wide {
    padding: 0rem 10rem;
  }
}


// animation

@mixin animation-cloud($name, $from, $to) {
  @keyframes float1 {
    0% { transform: translate3d(0,$from,0);}
    50% { transform: translate3d(0,$to,0);}
  }
}

@mixin animation-fly($name,$from,$to) {
  @keyframes bug1 {
    from {transform: rotate(0deg) translate(20px) rotate(0deg);}
    to {transform: rotate(-360deg) translate(20px) rotate(360deg);}
  }
  @keyframes bug2 {
    from {transform: rotate(0deg) translate(15px) rotate(0deg);}
    to {transform: rotate(360deg) translate(15px) rotate(-360deg);}
  }
}

@mixin backgroundAnimation {
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
}

// gradient day
@mixin backgroundGradientDay {
  background: linear-gradient(
    132deg,
    #FEECBD,
    #FFDFE2,
    #FEE7BD,
    #FFE0CC,
  );
  @include backgroundAnimation;
  background-size: 400% 400%;
  height: 100vh;
  animation: gradient 12s ease infinite;
}

// gradient night
@mixin backgroundGradientNight {
  background: linear-gradient(
    132deg,
    #5B2C8E,
    #5033C6,
    #3F34E1,
    #5B35D6,
    #650FC1,
    #4F0084,
  );
  @include backgroundAnimation;
  background-size: 400% 400%;
  height: 100vh;
  animation: gradient 12s ease infinite;
}

