@import "../../styles/partials/variables";
@import "../../styles/partials/mixins";

.footer {
  padding: 1.80rem;

  &__wrap {
    max-width: 1300px;
    margin: 0 auto;
    @include display-flex(row,space-between,center);
  }
  &__left {
    color: $accent-day;
    font-weight: 500;
    font-size: 16px;
    line-height: 31px;
    a.footer__ref {
      color: $accent-day;

    }
  }

  &__right {
    margin-bottom: 0rem;
    a {
      margin: 0rem 1rem;
    }
  }
  &__img {
    width: 30px;
  }
}

.footerDM {
  padding: 1.80rem;

  &__wrap {
    max-width: 1300px;
    margin: 0 auto;
    @include display-flex(row,space-between,center);
  }

  &__left {
    color: $accent-night;
    font-weight: 500;
    font-size: 16px;
    line-height: 31px;
    a.footerDM__ref {
      color: $accent-night;

    }
  }

  &__right {
    margin-bottom: 0rem;
    a {
      margin: 0rem 1rem;
    }
  }
  &__img {
    width: 30px;
  }
}