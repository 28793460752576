@import "../../styles/partials/variables";
@import "../../styles/partials/mixins";

.projectDesc {
  background-color: inherit;
  transition: 0.3s all ease;
  width: 70%;
  @include desktop {
    width: 42%;
  }

  &__title {
    font-size: 45px;
    line-height: 70px;
    font-weight: 500;
    margin-bottom: 24px;
  }
  &__info {
    font-size: 25px;
    line-height: 39px;
    font-weight: 400;
    margin-bottom: 45px;
  }
  &__bottom {
    width: 100%;
    @include display-flex(row,flex-start,center);
  }
  &__link {
    text-decoration: none;
    font-size: 20px;
    border: 2px solid $accent-day;
    width: 210px;
    height: 36px;
    padding: 0.5rem;
    color: $accent-day !important;
    @include display-flex(row,center,center);
    transition: 0.3s all ease;
    &--dm {
      color: $accent-night !important;
      border-color: $accent-night;
    }
  }
  &__otherLink {
    margin: 2rem 0rem 1.5rem 4rem;
    font-size: 21px;
    line-height: 34px;
    font-weight: 400;
    color: $accent-day !important;
    text-decoration: none;
    &--dm {
      color: $accent-night !important;
    }
  }

  &__link:hover {
    background-color: $accent-day;
    color: $background-day !important;
  }
  &__link:hover > .projectDesc__arrow {
    filter: brightness(0) saturate(100%) invert(88%) sepia(25%) saturate(421%) hue-rotate(337deg) brightness(104%) contrast(103%);
  }
  &__arrow {
    transition: 0.3s all ease;
    margin-left: 1rem;
    &--dm {
      filter: brightness(0) saturate(100%) invert(98%) sepia(65%) saturate(748%) hue-rotate(358deg) brightness(98%) contrast(96%);
    }
  }
  &__link--dm:hover {
    background-color: $accent-night;
    color: $background-night !important;
  }
  &__link--dm:hover > .projectDesc__arrow--dm {
    filter: brightness(0) saturate(100%) invert(19%) sepia(20%) saturate(421%) hue-rotate(255deg) brightness(88%) contrast(92%);
  }

}