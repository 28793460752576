@use "./fonts" as*;

// font variables
$font-main: 'Kumbh Sans', Arial, sans-serif;

// color variables
$background-day: #FFEEC1;
$background-night: #5B2C8E;

$font-lm: #212121;
$font-dm: #EEE;
$highlight-lm: #B43B65;
$highlight-dm: #D3C7FF;

// new ones
$accent-day: #AD3862;
$accent-night: #F5F488;

$cardText-day: #FEECBD;