@import "../../styles/partials/variables";
@import "../../styles/partials/mixins";

header {
  @include display-flex(row,space-between,center);
  transition: 0.3s all ease;
  max-width: 1300px;
  margin: 0 auto;
  padding: 2.5rem 2rem;

  @include wide {
    padding: 2.5rem 0rem;
  }

  &__homeLink {
    text-decoration: none;
  }

  .nav {
    &__list {
      @include display-flex(row,flex-end,center);
    }
    &__item {
      @include display-flex(row,center,center);
      padding-left: 3rem;
      letter-spacing: 1.8px;

      &--day {
        &:hover {
          color: $accent-day;
        }
      }
      &--night {
        &:hover {
          color: $accent-night;
        }
      }

    }
    &__link {
      cursor: pointer;
      text-decoration: none;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 12px;

      &--day {
        &:hover {
          color: $accent-day;
        }
      }
      &--night {
        &:hover {
          color: $accent-night;
        }
      }
    }
  }
}
#dmIcon {
  cursor: pointer;
}
.header-night {
  transition: 0.3s all ease;
}