@use "./styles/partials/reset" as *;
@import "./styles/partials/mixins";
@import "./styles/partials/variables";

html {
  height: 100%;
}
body {
  box-sizing: border-box;
  font-family: $font-main;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
}

.App {
  height: 100%;
  &__mobileMsg {
    margin-top: 50vh;
    text-align: center;
    font-size: 24px;
    padding: 0rem 2rem;
    @include tablet {
      display: none;
    }
  }
  &__core {
    display: none;
    @include tablet {
      display: block;
    }
  }
}

body.day {
  transition: 0.3s;
  @include backgroundGradientDay;
  height: 100%;
  margin: 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
  color: $font-lm;
  a {
    color: $font-lm;
  }
}

body.night {
  transition: 0.3s;
  @include backgroundGradientNight;
  height: 100%;
  margin: 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
  color: $font-dm;
  a {
    color: $font-dm;
  }
}